import React, { useEffect, useReducer, useRef, useState } from "react"
import { navigate } from "gatsby"
import InputMask from 'react-input-mask';
import SelectField from "./common/form/SelectField";
import InputField from './common/form/InputField';
import { getStorage, setStorage } from '../utils';
import ButtonField from "../components/common/form/ButtonField";
import request from '../services/request';
import { propertyType, hereAboutType, OFFER_STEP_PROPERTY_DETAILS } from "../constant"

const initialState = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  property_type: "",
  here_about_type: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return { ...state, [action.type]: action.value }
    default:
      throw new Error()
  }
}

const UserDetailsComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formSubmit, setFormSubmit] = useState(false)
  const inputRef = useRef();
  const alertValue = ["Mobile/Manufactured", "Raw Land"]

  const onValueChange = (e, type) => {
    if (alertValue.includes(e.target.value) && type === "property_type") {
      alert("Sorry, we do not buy mobile/manufactured homes or raw land at this time.")
    }
    dispatch({ type, value: e.target.value })
  }

  useEffect(() => {
    let initalValue = getStorage("formData") === null ? initialState : getStorage("formData")
    const {
      first_name = "",
      last_name = "",
      phone_number = "",
      email = "",
      property_type = "",
      here_about_type = "",
    } = initalValue
    let obj = { first_name, last_name, phone_number, email, property_type, here_about_type }
    Object.keys(obj).map((keyName, i) =>
      dispatch({
        type: keyName,
        value: obj[keyName] === null ? "" : obj[keyName],
      })
    )
    setStorage("addressSelect", false)
  }, [])

  const onSubmit = e => {
    e.preventDefault()
    setFormSubmit(true)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  useEffect(() => {
    if (formSubmit) {
      let initalValue = getStorage("formData")
      let finalValue = { ...initalValue, ...state }
      setStorage("formData", finalValue)
      const {
        first_name = null,
        last_name = null,
        email = null,
        phone_number = null,
        property_type = null,
        here_about_type = null,
        address = null,
        utm_source = null,
        utm_medium = null,
        utm_campaign = null,
        utm_network = null,
        utm_keyword = null,
        utm_landing = null,
        utm_device = null,
        utm_creative = null,
        utm_location = null,
        utm_content = null,
        utm_term = null,

      } = finalValue
      let data = {
        address,
        first_name,
        last_name,
        email,
        property_type,
        here_about_type,
        phone: phone_number,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_network,
        utm_keyword,
        utm_landing,
        utm_device,
        utm_creative,
        utm_location,
        utm_content,
        utm_term,
        clientId: window.ga.getAll()[0].get("clientId"),
      }
      request({
        url: "ov9vzjf",
        data,
      })
        .then(() => {
          // window.dataLayer = window.dataLayer || []
          // window.dataLayer.push({
          //   event: "new_lead_submit",
          //   dtL_new_lead_submit: {
          //     email: email,
          //     branch: process.env.GATSBY_BRANCH
          //   },
          // })          
          navigate(OFFER_STEP_PROPERTY_DETAILS)
          setFormSubmit(false)
        })
        .catch(err => {
          console.log("onConfirm-re", err)
        })
    }
  }, [formSubmit, state])

  const onBlur = (e, type) => {
    dispatch({ type, value: e.target.value.trim() })
  }

  return (<>
    <form className="needs-validation userDetailsContainer" onSubmit={e => onSubmit(e)}>
      <div className="container">
        <div className="text-center font-weight-bold mt-3 title">Where should we send your cash offer?</div>
        <div className="text-center mt-3 text-muted pl-4 pr-4 subTitle mb-4">We'll only use this information to get you your offer, and it won't be shared with anyone else.</div>
        <div className="row">
          <div className="col-6 pr-2 form-group required">
            <InputField
              id="firstName"
              placeholder="First Name"
              type="text"
              maxLength="50"
              pattern="^\w+(\s+\w+)*$"
              onBlur={e => onBlur(e, "first_name")}
              value={state.first_name}
              onChange={e => onValueChange(e, "first_name")}
              required={true}
              className="form-control rounded-0 input"
            />
          </div>
          <div className="col-6 pl-2 form-group required">
            <InputField placeholder="Last Name" maxLength="50" pattern="^\w+(\s+\w+)*$" onBlur={(e) => onBlur(e, "last_name")} value={state.last_name} onChange={(e) => onValueChange(e, "last_name")} className="form-control rounded-0 input" errorMsg="Please enter the vaild Last name" required={true} /> </div>
        </div>
        <div className="row">
          <div className="col-12 form-group required">
            <InputMask mask="(999) 999-9999" maskChar={null} value={state.phone_number} className="form-control rounded-0 input" placeholder="Phone Number" onChange={(e) => onValueChange(e, "phone_number")}>
              {(inputProps) =>
                <InputField type="tel" pattern="^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$" {...inputProps} required={true} errorMsg="Please enter the vaild Phone number" />
              }
            </InputMask>
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group required">
            <InputField placeholder="Email" maxLength="50" value={state.email} onChange={(e) => onValueChange(e, "email")} className="form-control rounded-0 input" type='email' errorMsg="Please enter the vaild Email" required={true} />
          </div>
        </div>
        <div className="row">
          <div className="col-6 pr-2 form-group required">
            <SelectField
              id="propertytype"
              className="form-control getAddressInput"
              options={propertyType}
              value={state.property_type}
              initialText="Select Property Type"
              onChange={e => onValueChange(e, "property_type")}
            />
          </div>
          <div className="col-6 pl-2 form-group required">
            <SelectField
              id="hereAboutType"
              className="form-control getAddressInput"
              options={hereAboutType}
              value={state.here_about_type}
              initialText="Where'd you hear about us?"
              onChange={e => onValueChange(e, "here_about_type")}
            />
          </div>
        </div>
        <div className="row mt-2 buttons mb-3">
          <div className="col-6 pr-2">
            <ButtonField className="btn-outline-secondary btnText rounded-0 btn-lg btn-block" name="Back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-6 pl-2">
            <button
              type="submit"
              className="btn btn-lg rounded-0 text-white btnHoverBackground btnHover btnText btn-block bgYellow"
              disabled={alertValue.includes(state.property_type)}
            >Continue</button>
          </div>
        </div>
      </div>
    </form>
  </>);
}

export default UserDetailsComponent;
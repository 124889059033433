import * as React from "react"

import Layout from "../../components/layout";
import UserDetails from "../../components/UserDetails";

const UserDetail = () => {
    return (
        <Layout title="get-offer-no-background">
            <UserDetails />
        </Layout>
    );
}

export default UserDetail;